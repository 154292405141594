<template>
    <div style="width: 100%;height: 100%">
        <div style="float: left">
            <el-button class="el-icon-back" round @click="goOff">返回</el-button>
        </div>
        <div style="color: #666666;float: right;margin: 5px 10px">案例金额：<span style="color: #EE3F4D;margin-right: 10px;">{{CaseInfo.money}}.00</span></div>
        <div style="clear: both;margin: 10px;text-align: center;font-size: 20px;color: #444444;font-weight: bold;">{{CaseInfo.title}}</div>
        <div class="follow" style="padding: 10px;margin-right:10px;min-height: 500px;clear: both" v-html="CaseInfo.content"></div>
    </div>
</template>
<script>

export default {
    props: ['id','show'],
    components: {

    },

    computed: {

    },
    data () {
        return {
            CaseInfo:{}
        }
    },
    created() {

    },
    mounted:function(){
         this.getShopCaseInfo()

    },
    methods:{
        // 案例详情
        getShopCaseInfo(){
            var that = this;
            that.newApi.getShopCaseInfo({
                id:that.id,
            }).then((res)=>{
                that.CaseInfo = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        goOff(){
            this.$emit('update:show',false);
        },
    }
}
</script>
<style scoped>

/deep/ .el-button {
    height:28px ;
    font-size: 12px;
}
/deep/ .el-button.is-round {
    padding: 5px 15px;
}
.follow>>>img {
    max-width: 100%;
}
</style>