<template>
    <div class="shop-info">
        <div style="width: 1500px; height: 64px; margin: 0 auto; display: flex; justify-content: center; line-height: 64px">
            <div>
                <span style="font-size: 20px; font-weight: 600; margin-right: 10px; vertical-align: middle">{{myshop.name}}</span>
                <span style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #2970FF; margin-right: 10px; vertical-align: middle">企业</span>
                <span style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #7BD7A2; margin-right: 10px; vertical-align: middle">实地认证</span>
                <span style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #FF9800; margin-right: 10px; vertical-align: middle">10年老店</span>
                <span v-if="myshop.status === 0" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #008000; margin-right: 10px; vertical-align: middle">保存</span>
                <span v-if="myshop.status === 1" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #008000; margin-right: 10px; vertical-align: middle">审核中</span>
                <span v-if="myshop.status === 2" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: red; margin-right: 10px; vertical-align: middle">审核未通过</span>
                <span v-if="myshop.status === 3" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #008000; margin-right: 10px; vertical-align: middle">通过</span>
                <span v-if="myshop.status === 4" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: red; margin-right: 10px; vertical-align: middle">查封</span>
            </div>
            <div style="color: #999999; margin: 0 10px">
                <span style="font-weight: 600;"> | </span>
                <span style="margin-left: 10px">店铺成立时间：</span>
                <span style="color: #2970FF">2013年</span>
            </div>
            <div style="color: #999999; margin: 0 10px">
                <span style="font-weight: 600;"> | </span>
                <span style="margin-left: 10px">店铺好评率：</span>
                <span style="color: #2970FF">100%</span>
            </div>
            <div v-if="user.uuid !== myshop.uuid" @click="toMessage(myshop.uuid, myshop.name)" class="hand op" style="margin-left: 180px; font-size: 18px">
                <i class="el-icon-chat-dot-round" style="color: #2970FF; margin-right: 2px"></i>
                联系店家
            </div>
            <div @click="followShop" v-if="user.uuid !== myshop.uuid && myshop.isCollect === 0" class="hand op" style="margin-left: 60px; font-size: 18px">
                <i class="el-icon-star-off" style="font-size: 20px; color: #999999"></i>
                关注店铺
            </div>
            <div @click="followShop" v-if="user.uuid !== myshop.uuid && myshop.isCollect === 1" class="hand op" style="margin-left: 60px; font-size: 18px">
                <i class="el-icon-star-on" style="color: #F12939; font-size: 20px;"></i>
                取消关注
            </div>
        </div>
        <div style="width: 100%;height: 120px;background-size: 100% 120px;background-position:center;background-repeat: no-repeat;" :style="{'background-image': `url(${myshop.backgroundImg.img_url})`}"  >
            <img :src="myshop.logo.img_url" style="width: 120px; height: 120px; margin-left: 248px;">
        </div>

        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import ToMessage from "../order/ToMessage";
export default {
    name: "ShopInfo",
    components: {ToMessage},
    data() {
        return {
            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    props: {
        myshop: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {
        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },

        // 关注、取消关注店铺
        followShop() {
            this.newApi.collectShop({shopId: this.myshop.id}).then((res)=>{
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data)
                    this.$parent.getMyShop()
                }
            })
        },
    }
}
</script>

<style scoped>
.shop-info {

}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}
</style>
