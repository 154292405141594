<template>
    <div style="width: 100%;overflow: hidden">
        <div style="margin-top: 90px">
            <ShopInfo :myshop="myshop"></ShopInfo>
        </div>
        <div style="justify-content: center;display: flex">
            <div style="width: 100%;height: auto;overflow: hidden;display: flex;justify-content: center;">
              <div style="width: 1500px;display: flex">
                  <div style="width:55%;">
                      <div style="display: flex">
                          <div style="width: 620px;height: 364px;margin-top: 8px;">
                              <img :src="selectedImage" style="width: 628px;height: 366px;border-radius: 3px 3px 3px 3px;object-fit: cover;">
                          </div>
                          <!--   右边图片-->
                          <div  style="width: 180px;margin-left: 10px;height: 365px;margin-top: 1px">
                              <div class="title_img">
                                  <div v-for="(item,index) in serviceInfo.images" :key="index"  style="width: 183px;height: 180px;margin-top:6px;">
                                      <img :src="item.url"  @click="select_url(item)" style="width: 183px;height: 180px;border-radius: 3px 3px 3px 3px;cursor: pointer;object-fit: cover;">
                                  </div>
                              </div>
                          </div >
                      </div>
                      <div style="width: 815px;height:auto;margin-top: 15px">
                          <serviceIntroduction :introduction="serviceInfo"></serviceIntroduction>
                      </div>
                  </div>
                  <div style="width: 35%;">
                      <!--   标题    -->
                      <div style="margin: 20px;width: 100%;height: 66px;">
<!--                          <div v-for="(item,index) in serviceInfo.label" :key="index" style="color: #444444;font-size: 24px;font-weight:bold;cursor: pointer;float: left;white-space: nowrap;" >{{item}}-->
                              <div style="color: #444444;font-size: 24px;font-weight:bold;cursor: pointer;overflow:hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;">{{serviceInfo.title}}</div>
<!--                          </div>-->
                      </div>
                      <div style="width: 614px;height: 56px;background-color: #FFFFFF;margin: 20px;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 5px;clear: both">
                          <div style="font-size: 20px;margin: 15px 30px;height: 33px;float: left;font-weight:bold;">价格<span v-if="serviceInfo.maxPrice !== 0">范围</span></div>
                          <div style="font-size: 28px;color: #EE3F4D;padding-top: 10px;float: left;font-weight:bold;cursor: pointer">￥{{ serviceInfo.minPrice }}<span v-if="serviceInfo.maxPrice !== 0">-{{ serviceInfo.maxPrice }}</span></div>
                          <div style="color: #999999;height: 24px;float: right;margin: 16px 10px;">月销量800+</div>
                      </div>

                      <div v-if="serviceInfo.otherSpecifications.length" style="width: 614px;height: auto;overflow: hidden;background: #FFFFFF;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);margin-left: 20px;border-radius: 5px;">
                          <table border="1" style="width: 100%;height: auto;overflow: hidden;font-size: 14px;">
                              <tr v-for="(item,index) in serviceInfo.otherSpecifications" :key="index" style="border: 2px solid #EBEBEB;">
                                  <th class="bold"  colspan="2" style="border: 2px solid #EBEBEB;"><div>{{item.key}}</div></th>

                                  <td class="bold" v-if="item.type===1 || item.type===2">
                                      <div style="margin-left: 20px;">{{item.value}}</div>
                                  </td>

                                  <td class="bold"  v-if="item.type === 4">
	                                  <div style="text-align: center" v-if="item.userType==2">
		                                  <span v-for="(selectItem, selectIndex) in item.value" :key="selectIndex" >{{selectItem.name}}<span v-if="selectIndex !== item.value.length -1">/</span></span>
	                                  </div>
	                                  <div style="text-align: center" v-else>
		                                  <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
			                                  <span v-if="item.selectList.includes(selectItem.radio)">{{ selectItem.name }}</span>
		                                  </div>
	                                  </div>
                                  </td>
                                  <td class="bold" v-if="item.type === 3 || item.type === 5">
	                                  <div style="text-align: center" v-if="item.userType==2">
		                                  <span v-for="(selectItem, selectIndex) in item.value" :key="selectIndex" >{{selectItem.name}}<span v-if="selectIndex !== item.value.length -1">/</span></span>
	                                  </div>
	                                  <div style="text-align: center" v-else>
		                                  <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
			                                  <span v-if="selectItem.radio==item.radio">{{selectItem.name}}</span>
		                                  </div>
	                                  </div>
	                                  
                                  </td>
	                              <td class="bold"  v-if="item.type === 6">
		                              <div style="width: 100%;"  v-if ="item.userType == 2 ">
			                              <div v-model="item.data" style="width: 100%;">
				                              <div style="width: 100%;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" :style="pindex==item.value.length-1?'':'border-bottom: 1px solid #BABABA'">
					                              <div style="width: 20%; overflow: hidden; float: left;text-align: center;">
						                              <span style="height: auto;">{{ p.name }}</span>
					                              </div>
					                              <div style="width: calc(80% - 2px);overflow:hidden; font-size: 16px; border-left: 1px solid #BABABA">
						                              <div style="overflow:hidden; float: left; width:100%; border-right: 1px solid #BABABA">
							                              <div v-for="(k,kindex) in p.value" :key="kindex" style="line-height: 40px;height:auto;overflow:hidden;text-align: center;" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid #BABABA'">{{ k }}</div>
						                              </div>
					                              </div>
				                              </div>
			                              </div>
		                              </div>
		                              <div style="width: 100%;"  v-else >
			                              <div style="width: 100%;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" >
				                              <div style="width: 20%; overflow: hidden; float: left;text-align: center;" v-if="p.radio==item.radio">
					                              <span v-if="p.radio==item.radio">{{p.name}}</span>
				                              </div>
				                              <div v-if="p.radio==item.radio" style="width: calc(80% - 2px);overflow:hidden; font-size: 16px; border-left: 1px solid #BABABA;">
					                              <div style="overflow:hidden; float: left; width:100%; border-right: 1px solid #BABABA" >
						                              <div v-for="(k,kindex) in p.value" :key="kindex" style="line-height: 40px;height:auto;overflow:hidden;text-align: center;" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid #BABABA'">
							                              {{ k }}
						                              </div>
					                              </div>
				                              </div>
			                              </div>
		                              </div>
	                              </td>
                              </tr>
                          </table>
                          <div v-if="myshop.uuid !== user.uuid" style="display: flex;justify-content: center;margin-top: 30px;">
                              <el-button @click="toMessage(myshop.uuid, myshop.name)" class="el-icon-chat-dot-round" plain>在线咨询</el-button>
                              <el-button type="primary" @click="toBuyService">购买服务</el-button>
                          </div>
                          <div style="padding-bottom: 20px;"></div>
                      </div>
	                  <div  style="width: 614px;height: auto;margin-top: 30px;overflow: hidden;background: #FFFFFF;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);margin-left: 20px;border-radius: 5px">
		                  <h3 style="padding: 10px">服务流程</h3>
		                    <div style="width: 100%;height: auto;" v-for="(p,pIndex) in serviceInfo.flowPath" :key="pIndex">
			                    <div style="width: 20%;height: 30px;float: left;text-align: center">第{{ pIndex +1 }}阶段:</div>
			                    <div style="width: 80%;min-height: 30px;float: left">{{ p.value }}</div>
		                    </div>
	                  </div>
                  </div>
              </div>
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
    </div>

</template>
<script>
import serviceIntroduction from "./components/myShop/serviceIntroduction";
import ShopInfo from "./components/myShop/ShopInfo";
import ToMessage from "./components/order/ToMessage";
export default {
    components: {
        ToMessage,
        serviceIntroduction,
        ShopInfo
    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            selectedImage: '',
            serviceInfo: {
                images: []
            },
            myshop:{},

            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    created() {
        this.getShopServiceInfo();
    },
    mounted:function(){
        if (this.serviceInfo.images.length > 0) {
            this.selectedImage = this.serviceInfo.images[0].url;
        }

    },
    methods:{
        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },

        // 购买服务
        toBuyService() {
            var that = this;
            if (!this.user.corpMemberId && !this.user.individMemberId) {
                this.utils.confirm('此账号未绑定银行卡，不能购买服务，是否前往绑定？', function () {
                    that.$router.push({path: '/user/money'})
                })
            } else {
                that.$router.push({path: '/shop/serviceVerify/' + that.serviceInfo.id, query: {roomId: that.$route.query.roomId, sequenceId: that.$route.query.taskId}})
            }
        },

        // 商务信息详情
        getShopServiceInfo:function(){
            var that = this;
            that.newApi.getShopServiceInfo({
                serviceId:that.$route.params.id,
            }).then((res)=>{
                that.serviceInfo = res.data;
                if (that.serviceInfo.images.length > 0) {
                    that.selectedImage = that.serviceInfo.images[0].url;
                }
                that.getMyShop(res.data.shopId);
            }).catch((err)=>{
                console.log(err)
            })
        },
        select_url(item){
            console.log(item)
            this.selectedImage = item.url
        },
        getMyShop(shopId){
            const that = this;
            that.newApi.getMyShop({id:shopId}).then((ret)=>{
                that.myshop = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

    }
}
</script>
<style scoped>
.bold {
    height: 30px;
}
table {
    border-collapse: collapse;
    width: 612px;
    height: auto;
    overflow: hidden;
}

th, td {
    border: 1px solid black;
    padding: 8px;
}

.shadow {
    box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.16);
    border-radius: 3px 3px 3px 3px;
}
.title_img{
    overflow: hidden;
    overflow-y: scroll;
    max-height: 372px;
    width: 188px;
}
.title_img::-webkit-scrollbar {
    display: none;
}
.th[data-v-16c18cb4], td[data-v-16c18cb4]{
    padding: 0px;
}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}
</style>
