<template>
  <div style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);height: 100%">
    <div style="width: 815px;height: auto;float:left;margin-top: 15px;">
      <el-tabs v-model="activeName" type="border-card" @tab-click="checkoutTabs">
        <el-tab-pane v-for="item in shop_list" :key="item.type" :label="item.name" :name="item.type.toString()"
                     style="width: 800px;">
          <!--   服务介绍     -->
          <div v-if="activeName == 1">
            <div class="follow" style="float: left;font-weight:bold;">服务介绍:</div>
            <div class="follow" style="margin-right: 15px;min-height: 30px" v-html="introduction.content"></div>
            <!--      服务输入条件    -->
            <div class="follow" style="float: left;font-weight:bold;">服务输入条件:</div>
            <div v-for="(input,int) in introduction.input" :key="int" style="padding-top: 5px;min-height: 100px;">
                  <div style="clear: both;margin-top: 10px"><span style="font-size: 16px">{{ int + 1 }}.</span>{{ input }}</div>
            </div>
            <!--      服务输出形式    -->
            <div class="follow" style="float: left;font-weight:bold;">服务输出形式:</div>
            <div v-for="(output,intcc) in introduction.output" :key="intcc" style="padding-top: 5px;min-height: 100px;">
              <div style="clear: both;margin-top: 10px"><span style="font-size: 16px">{{ intcc + 1 }}.</span>{{ output }}</div>
            </div>
            <!--      服务达到的标准        -->
            <div class="follow" style="float: left;font-weight:bold;">服务达到的标准:</div>
            <div v-for="(standard,intyy) in introduction.standard" :key="intyy" style="padding-top: 5px;min-height: 100px;">
              <div style="clear: both;margin-top: 10px"><span style="font-size: 16px">{{ intyy + 1 }}.</span>{{ standard }}</div>
            </div>

            <!--      服务标签         -->
            <div class="follow" style="float: left;font-weight:bold;">服务标签:</div>
            <div style="clear: both;margin-left: -18px">
                  <div v-for="(label,index) in introduction.label" :key="index" style="cursor: pointer;margin-left: 20px;margin-top: 10px;float: left">
                    <el-tag style="font-size: 16px;">{{ label }}</el-tag>
                  </div>
            </div>

            <div style="margin-bottom: 50px;"></div>
          </div>

          <!--  成功案例  -->
          <div v-if="activeName == 2" style="min-height: 500px;">
            <div v-if="show" style="width: 100%;height: 100%">
              <caseDetails :id="Details" :show.sync=show></caseDetails>
            </div>
            <label v-else>
              <div v-for="(label,index) in introduction.caseList" :key="index" class="tox"
                   @click="caseDetails(label.id)">
                <div style="width: 222px;height: 134px;margin:10px;">
                  <img :src="label.img" style="width: 222px;height: 134px;border-radius: 5px;">
                </div>
                <div style="height: 41px;font-size: 17px;margin: 0 10px 0 10px;color: #444444;">{{ label.title }}</div>
                <div style="color: #666666;float: right;margin: 10px 10px">案例金额：<span
                    style="color: #EE3F4D">{{ label.money }}.00</span></div>
              </div>
            </label>
          </div>
          <!--  交易保障 -->
          <div v-if="activeName == 3" style="min-height: 500px">
            <label v-if="introduction.commitment.length > 0">
              <div style="float: left;margin-top: 20px ">
                <img src="~@/assets/imgs/index/yusan.png" style="width: 26px;height: 26px;">
              </div>
              <div style="font-size: 20px;height: 24px;color: #2970FF;float: left;margin: 18px 10px">商家承诺</div>
            </label>
            <div class="ply" style="clear: both;font-weight:bold;"
                 v-for="(i,k) in introduction.commitment" :key="k"><span
                style="font-size: 17px">{{ k + 1 }}.</span>{{ i }}
            </div>
            <label v-if="introduction.link.length > 0">
              <div style="float: left;margin-top: 40px">
                <img src="~@/assets/imgs/index/yusan.png" style="width: 26px;height: 26px;">
              </div>
              <div style="font-size: 20px;height: 24px;color: #2970FF;float: left;margin: 38px 0 0 10px">相关配套服务
              </div>
              <div style="width: 250px;height: 361px;border: 1px solid #DCDCDC;clear: both;margin: 15px 0 0 30px;">
                <div style="width: 214px;height: 150px;border: 1px solid #DCDCDC;margin: 16px 0 0 18px;"></div>
                <div v-for="(item,index) in 4" :key="index"
                     style="width: 40px;height: 30px;border: 1px solid #DCDCDC;margin: 10px 0 0 18px;float: left"></div>
                <div
                    style="color: #FF0000;height: 24px;font-size: 20px;clear: both;margin-top: 50px;margin-left: 18px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis">
                  ￥50/3套方案满意为止
                </div>
                <div
                    style="height: 37px;font-size: 14px;color: #444444;margin-left: 18px;margin-top: 12px;overflow:hidden;text-overflow: ellipsis;-o-text-overflow:ellipsis">
                  网站定制开发/门户网站/企业官网/ PHP/Java/模板建站
                </div>
                <div style="height: 16px;font-size: 14px;color: #999999;float: left;margin-top: 10px;margin-left: 18px">
                  成交量：<span style="color: #FF9800"></span>3542
                </div>
                <div style="float: right;margin-right: 10px;">
                  <el-button class="el-icon-user" type="warning">免费咨询</el-button>
                </div>
              </div>
            </label>
          </div>
          <!--   服务评价     -->
          <div v-if="activeName == 4" style="min-height: 500px">
            <div
                style="width: 746px;height: 80px;padding: 10px; margin-left:10px;">
              <div style="float: left; width: 20%;text-align: center;">
                <div style="font-size: 18px; font-weight: 600; margin-bottom: 6px; color: #666666">服务综合评分</div>
                <div style="font-size: 30px;color: #F12939; font-weight: 600">4.8分</div>
              </div>
              <div style="width: 80%;">
                <!--                                  <div v-for="(label,index) in introduction.label" :key="index" style="border-radius: 3px;border: 1px solid #D2D2D2;float: left;margin-left: 20px;margin-top: 15px">&ndash;&gt;-->
                <!--                                    <div style="width: auto;font-size: 16px;color: #000000;margin: 5px 15px;float: left;cursor: pointer">{{label}}</div>-->
                <!--                                  </div>-->
              </div>
            </div>
            <div style="padding: 20px">
              <div v-for="(item, index) in appraiseList" :key="index">
                <div style="display: flex; margin-bottom: 10px">
                  <img :src="item.userInfo.headIco" style="width: 48px; height: 48px; border-radius: 50%; margin-right: 10px">
                  <div style="width: 140px">
                    <div
                        style="margin-bottom: 4px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                      {{ item.userInfo.nickName }}
                    </div>
                    <div></div>
                  </div>
                </div>
                <div style="margin-bottom: 10px">
                  {{item.content}}
                </div>
                <div style="display: flex;">
                  <div style="color: #999999; margin-right: 10px">
                    成果：<el-rate style="display: inline-block; vertical-align: middle" v-model="item.serviceAchievements" color="#FF0000" :disabled="true" :colors="['#E4393C', '#E4393C', '#e4393c']"></el-rate>
                  </div>
                  <div style="color: #999999; margin-right: 10px">
                    专业：<el-rate style="display: inline-block; vertical-align: middle" v-model="item.serviceMajor" color="#FF0000" :disabled="true" :colors="['#E4393C', '#E4393C', '#e4393c']"></el-rate>
                  </div>
                  <div style="color: #999999; margin-right: 10px">
                    态度：<el-rate style="display: inline-block; vertical-align: middle" v-model="item.serviceAttitude" color="#FF0000" :disabled="true" :colors="['#E4393C', '#E4393C', '#e4393c']"></el-rate>
                  </div>
                  <div style="color: #999999;">
                    {{item.updateTime}}
                  </div>
                </div>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-bottom: 50px;"></div>
    </div>
  </div>
</template>
<script>

import caseDetails from "./caseDetails"

export default {

  components: {
    caseDetails
  },

  computed: {
    user() {
      return this.$store.state.user
    },
  },
  props: {
    introduction: {
      type: Object,
      default() {
        return {}
      }
    },

    id: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      shop_list: [{name: "服务介绍", type: 1}, {name: "成功案例", type: 2}, {
        name: "交易保障",
        type: 3
      }, {name: "服务评价", type: 4}],
      cache: 1,
      activeName: null,
      show: false,
      Details: '',

      // 评价
      appraiseList: [],
    }
  },

  mounted: function () {
    this.activeName = this.shop_list[0].type.toString();
  },
  methods: {
    add_list(type) {
      this.cache = type;
    },
    caseDetails(id) {
      this.show = true;
      this.Details = id;
    },

    // 切换标签
    checkoutTabs(e) {
      if (e.name == '4') {
        this.getServiceAppraise()
      }
    },

    // 获取服务评分
    getServiceAppraise() {
      this.newApi.getShopAndServiceEvaluateList({specificId: this.$route.params.id, type: 1}).then(res => {
        this.appraiseList = res.data
      })
    },

  }
}
</script>
<style scoped>
.active {

  color: #2970FF;
  border-bottom: 2px solid #2970FF;

}

.pay-type-name {
  height: 21px;
  font-size: 18px;
  cursor: pointer;
}

.follow {
  margin-top: 10px;
  clear: both;
  font-size: 17px;
  height: auto;
  color: #444444;

}

.tox {
  width: 246px;
  height: 246px;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px 5px 5px 5px;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
}

.ply {
  margin: 10px 30px;
  font-size: 16px;
}

.follow >>> img {
  max-width: 97%;
}

/deep/ .el-tabs__item.is-top {
  font-size: 16px;
}
</style>
